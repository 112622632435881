<template>
</template>

<script>
  export default {
    name: 'home',
    data() {
      return {
      }
    },
    mounted() {
      const that = this
      const code = that.getUrlParam('code')
      const rid = sessionStorage.getItem('rid')
      console.log("rid   "+rid)
      if(rid == null || rid == "") {
        console.log("code"+code)
        if(code == null || code == "") {
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?' +
            'appid=wx5e1224dd4c74fd15' +
            '&redirect_uri='  + encodeURIComponent(window.location.href) +
            '&response_type=code' +
            '&scope=snsapi_userinfo' +
            '&state=STATE#wechat_redirect'
        } else {
          console.log("开始发送请求"+this.$url+'wx/login')
          that.$axios.post(this.$url+'wx/login','code='+code)
            .then(function (res) {
              console.log(res)
              if (res.data != ""){
                sessionStorage.setItem('rid',res.data)
                // window.location.href = '/basketball'
                that.$router.replace('/tennis')
              }else {
                // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?' +
                //   'appid=wx5e1224dd4c74fd15' +
                //   '&redirect_uri='  + encodeURIComponent(window.location.href) +
                //   '&response_type=code' +
                //   '&scope=snsapi_userinfo' +
                //   '&state=STATE#wechat_redirect'
              }
            })
        }

      }else {
        that.$router.replace('/tennis')
      }
    },
    methods: {
      getUrlParam(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
        // console.log(reg);
        let r = decodeURI(window.location.search).substr(1).match(reg);  //匹配目标参数
        // console.log(window.location.search.substr(1).match(reg));
        if (r != null) return unescape(r[2]);
        return null; //返回参数值
      }
    }
  }
</script>

<style scoped>

</style>
