<template>
  <div class="container-fluid">
    <div class="row" style="padding-left: 1%;padding-right: 1%">
      <div class="col-xs-12 d">
        <img src="../assets/image/dylogo.png" style="width: 60vw;height: 75vw">
      </div>
      <div class="col-xs-12 ">
        <h3 style="text-align: center;font-weight: bolder">团购自助验券</h3>
      </div>
      <div class="col-xs-12 d" style="margin-top: 30px">
        <input v-model="code" placeholder="请输入券码" style="border: none;width: 60vw;outline: none;"
               type="text">
      </div>
      <div class="col-xs-12 d">
        <div style=" border-top: 1px solid #ccc;
  margin: 10px 0;width: 60vw"></div>
      </div>
      <div class="col-xs-12 d" style="margin-top: 30px">
        <button class="btn btn-info" style="width: 60vw;height: 5vh;font-size: medium;font-weight: bolder" type="button" @click="s">确认</button>
      </div>
      <div class="col-xs-12" style="text-align: center;margin-top: 5vh">
        <button class="btn btn-warning" style="width: 30vw;height: 8vw;font-size: medium;font-weight: bolder" type="button" @click="back" >返回</button>
      </div>
      <loading ref="lg"></loading>
      <warn ref="wn"></warn>
      <dyv ref="dyv"></dyv>
      <foot></foot>
    </div>
  </div>
</template>

<script>
import Warn from '@/components/modular/warn'
import Loading from '@/components/modular/loading'
import Foot from '@/views/foot'
import Dyv from '@/components/modular/dyv.vue'

export default {
  name: 'dy',
  components: {
    Dyv,
    Warn,
    Foot,
    Loading
  },
  data () {
    return {
      rid: sessionStorage.getItem('rid'),
      code: '',
      ready: false
    }
  },
  created () {
    const that = this

  },
  methods: {
    back() {
      this.$router.push('/user')
    },
    s () {
      const that = this
      if (this.code != null) {
        this.$axios.get(this.$url + '/dy/preCode?code=' + this.code + '')
          .then(function (res) {
            console.log(res.data.data)
            let data = res.data.data
            if (data.error_code == 0) {
              if (data.certificates.length > 0) {
                that.$refs.dyv.code = data.certificates[0].encrypted_code
                that.$refs.dyv.token = data.verify_token
                let name = data.certificates[0].sku.title
                if (name.indexOf('教练') === -1) {
                  // if (name.indexOf("30分钟") !== -1 && name.indexOf("教练") === -1) {
                  that.$refs.dyv.show = true
                } else {
                  that.$refs.wn.warnText = '该团购券不可自助兑换，请联系客服！'
                  that.$refs.wn.showWarn = true
                  setTimeout(function () {
                    that.$refs.wn.showWarn = false
                  }, 1000)
                }
              }else {
                that.$refs.wn.warnText = '该优惠券已被使用'
                that.$refs.wn.showWarn = true
                setTimeout(function () {
                  that.$refs.wn.showWarn = false
                }, 1000)
              }
            } else {
              that.$refs.wn.warnText = '请输入正确券码'
              that.$refs.wn.showWarn = true
              setTimeout(function () {
                that.$refs.wn.showWarn = false
              }, 1000)
            }

          })
      }
    }
  }
}
</script>

<style>
.d {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::placeholder {
  text-align: center;
}
</style>
