<template>
  <div class="container-fluid">
    <div class="row" style="padding-left: 1%;padding-right: 1%">
      <div class="page__hd">
        <h1 class="page__title" style="text-align: center">查询订单</h1>
        <div class="col-xs-12" style="text-align: center;margin-top: 10px">
          <button type="button" @click="back" class="btn btn-warning" style="margin-right: 3px;width: 30vw;height: 8vw;font-size: medium;font-weight: bolder">返回</button>
          <!--<div class="btn-group" v-show="items.length > 0">-->
            <!--<button type="button"-->
                    <!--class="btn btn-info dropdown-toggle"-->
                    <!--data-toggle="dropdown"-->
                    <!--aria-haspopup="true"-->
                    <!--aria-expanded="false">-->
              <!--筛选<span class="caret"></span>-->
            <!--</button>-->
            <!--<ul class="dropdown-menu">-->
              <!--<li><a @click="show = 'basketball'">只看篮球</a></li>-->
              <!--<li><a @click="show = 'tennis'">只看网球</a></li>-->
              <!--<li><a @click="show = 'all'">查看所有</a></li>-->
            <!--</ul>-->
          <!--</div>-->
        </div>
      </div>
      <div v-if="items.length > 0" v-for="(item) in items" v-show="show === 'all'|| show === item.court"
           class="col-xs-12 code "
           :class="getClass(item.pay)"
      >
        <p v-if="item.pay === 0">交易成功<img :src="item.wxUser.headimgurl"
                                          style="width: 20px;height: 20px"></p>
        <p v-else>交易取消<img :src="item.wxUser.headimgurl"
                           style="width: 20px;height: 20px"></p>
        <img :src="getCourtIMG(item.court)"
             class="court">
        <div class="codetxt">
          <p style="margin-top: 0;text-align: center;font-size: 18px"
             v-if="item.court==='basketball'">
            室内篮球场</p>
          <p style="margin-top: 0;text-align: center;font-size: 18px"
             v-if="item.court ==='tennis'">
            室内网球场</p>
          <p>{{ timestampTotime(item.begintime) }}</p>
          <p>{{ getDmoney(item.dmoney) }}</p>
        </div>
        <button type="button"
                class="btn btn-default"
                @click="findOrder(item.oid)">查看订单
        </button>
      </div>
      <h4 v-else id="text">暂无订单</h4>
      <loading ref="lg"></loading>
      <warn ref="wn"></warn>
      <foot></foot>
    </div>
  </div>
</template>

<script>
  import Warn from '@/components/modular/warn';

  import Loading from '@/components/modular/loading';
  import $ from 'jquery';
  import Foot from '@/views/foot';
  import basketballIMG from '@/assets/image/basketballCourt.jpg';
  import tennisIMG from '@/assets/image/tennisCourt.jpg';

  export default {
    name: 'orderList',
    components: {
      Warn,
      Foot,
      Loading
    },
    data () {
      return {
        rid: sessionStorage.getItem('rid'),
        src: '',
        court: '',
        items: [],
        basketballIMG: basketballIMG,
        tennisIMG: tennisIMG,
        show: 'all'
      };
    },
    created () {
      const that = this;
      this.$axios.post(this.$url + 'findOrderList', 'rid=' + this.rid)
        .then(function(res) {
          let data = res.data;
          if (data.length != null) {
            that.items = data.sort(that.compare('time'));
          }
        });
    },
    methods: {
      compare(p){ //这是比较函数
        return function(m,n){
         let a = m[p];
         let b = n[p];
          return b - a; //升序
       }
      },
      back() {
        this.$router.push('/user')
      },
      timestampTotime (time) {
        if (time != null){
          let date = new Date(time + 8 * 3600 * 1000);
          return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '.');
        }
      },
      findOrder (oid) {
        const that = this
        that.$router.push({
          name: 'Order',
          query: {
            oid: oid,
          }
        })
      },
      getDmoney (dmoney) {
        if (dmoney !== 0) {
          return '￥  ' + dmoney
        } else {
          return '免费'
        }
      },
      getCourt(court){
        if (court === 'basketball') {
          return "basketballcourt"
        }
        if (court === 'tennis') {
          return "tenniscourt"
        }
      },
      getClass (pay) {
        if (pay === 0) {
          return 'pay' + 0
        } else {
          return 'pay' + 2
        }
      },
      getCourtIMG (court) {
        if (court === 'basketball') {
          return this.basketballIMG
        }
        if (court ==='tennis') {
          return this.tennisIMG
        }
      },
    }
  };
</script>

<style>
  p {
    margin: 0;
    font-size: 15px;
  }

  .code {
    margin-top: 10px;
    padding: 0 3% 2% 3%;
    border-radius: 20px;
    border: orange solid 1px;
  }

  .code p {
    margin-top: 8px;
    margin-bottom: 10px;
    padding-left: 5px;
    font-size: 16px;
    font-weight: bold;
  }

  .code img {
    border-radius: 10px;
    float: left;
    margin-left: 2%;
  }

  .code button {
    float: right;
    border-radius: 15px;
    margin-top: 1%;
    margin-right: 3%;
  }

  .codetxt {
    width: 55%;
    float: left;
    margin-left: 15px;
  }

  .codetxt p {
    text-align: right
  }

  .row {
    margin-bottom: 70px;
    padding-left: 1%;
    padding-right: 1%
  }

  .court {
    width: 36%;
    height: 30%;
  }
  #text{
    text-align: center;
    margin-top: 80px;
    color: #6d6d6d;
  }
</style>
