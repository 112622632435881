<template>
  <!--BEGIN dialog3-->
  <div class="js_dialog"
       v-show="showOrderForm">
    <div class="weui-mask"></div>
    <div class="weui-dialog ">
      <h4 style="text-align: center;font-weight: bold">请填写订单信息</h4>
      <div class="weui-dialog__bd"
           style="padding: 0;margin: 0">
        <div class="weui-form__control-area"
             style="margin: 0">
          <div class="weui-cells__group weui-cells__group_form">
            <transition name="fade">
            <div class="weui-cells weui-cells_form "
                 v-show="first">
<!--              <div class="weui-cell weui-cell_active">-->
<!--                <div class="weui-cell__hd"><label class="weui-label">联系人</label></div>-->
<!--                <div class="weui-cell__bd">-->
<!--                  <input v-model="name"-->
<!--                         class="weui-input"-->
<!--                         placeholder="（*必填）"-->
<!--                         maxlength="7"/>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="weui-cell weui-cell_active">-->
<!--                <div class="weui-cell__hd"><label class="weui-label">队伍名称</label></div>-->
<!--                <div class="weui-cell__bd">-->
<!--                  <input v-model="teamName"-->
<!--                         class="weui-input"-->
<!--                         placeholder="（*必填）"-->
<!--                         maxlength="10"/>-->
<!--                </div>-->
<!--              </div>-->
              <div class="weui-cell weui-cell_active">
                <div class="weui-cell__hd"><label class="weui-label">联系电话</label></div>
                <div class="weui-cell__bd">
                  <input v-model="phone"
                         class="weui-input"
                         placeholder="（*必填）"
                         type="number"
                         pattern="[0-9]*"/>
                </div>
              </div>
            </div>
            </transition>
            <transition name="fade">
            <div class="weui-cells__group weui-cells__group_form "
                 v-show="second">
              <div class="weui-cells weui-cells_checkbox">
                <label class="weui-cell weui-cell_active weui-check__label">
                  <div class="weui-cell__hd">
                    <input type="checkbox"
                           class="weui-check"
                           name="checkbox1"
                           v-model="water"/>
                    <i class="weui-icon-checked"></i>
                  </div>
                  <div class="weui-cell__bd">
                    <p>购买纯净水</p>
                    <p>24支550mL ￥45</p>
                  </div>
                </label>
              </div>
            </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="weui-dialog__ft">
        <a @click="abolish()"
           v-show="first"
           class="weui-dialog__btn weui-dialog__btn_default confirm">取消</a>
        <a @click="next()"
           v-show="first"
           class="weui-dialog__btn weui-dialog__btn_primary confirm">下一步</a>
<!--        <a @click="back()"-->
<!--           v-show="second"-->
<!--           class="weui-dialog__btn weui-dialog__btn_default confirm">上一步</a>-->
<!--        <a @click="confirm()"-->
<!--           v-show="second"-->
<!--           class="weui-dialog__btn weui-dialog__btn_primary confirm">下一步</a>-->
      </div>
    </div>
  </div>
  <show-free ref="sf"></show-free>
</template>

<script>
  import ShowFree from './showFree'
  export default {
    components: { ShowFree },
    name: 'orderForm',
    data() {
      return {
        showOrderForm: false,
        second: false,
        first: true,
        str: '',
        name: '',
        teamName: '',
        phone: '',
        water: true,
        referee: 0,
        free: false,
        court: '',
        pL: 0,
      }
    },
    mounted () {

    },
    methods: {
      abolish () {
        this.name = ''
        this.teamName = ''
        this.phone = ''
        this.showOrderForm = false
      },
      back () {
        const that = this
        this.second = false
        setTimeout(function() {
          that.first = true
        }, 500);
      },
      isPhone () {
          const myreg = /^1[3-9]\d{9}$/
          if (!myreg.test(this.phone)) {
            return false
          } else {
            return true
          }
      },
      next () {
        const that = this
        if (that.phone !== '') {
        // if (that.name.length > 0 && that.teamName.length > 0 && that.phone !== '') {
          // eslint-disable-next-line no-undef
          if (that.isPhone()) {
            that.first = false
            that.findFreeOfRole()
            // myEndFunction1()
          } else {
            that.$parent.$refs.warn.warnText = '请填写正确手机号码'
            that.showOrderForm = false
            that.$parent.$refs.warn.showWarn = true
            setTimeout(function() {
              that.$parent.$refs.warn.showWarn = false
              that.showOrderForm = true
            }, 1000)
          }
        } else {
          that.showOrderForm = false
          that.$parent.$refs.warn.warnText = '请填完整信息'
          that.$parent.$refs.warn.showWarn = true
          setTimeout(function() {
            that.$parent.$refs.warn.showWarn = false
            that.showOrderForm = true
          }, 1400)
        }
      },
      findFreeOfRole() {
        const that = this
        let url = '/dy/findDyCardByRid'
        this.$axios.post(that.$url + url,'rid='+ sessionStorage.getItem('rid'))
        .then(function(res){
          let data = res.data
          if (data != null) {
            if (data.length !== 0) {
              that.showOrderForm = false
              // that.$refs.sf.freeText = '你还有' + data + '次免费机会，是否使用？'
              that.$refs.sf.FreeList = data
              that.$refs.sf.pl2 = that.$parent.pickList.length
              that.$refs.sf.showFree = true
            } else {
              that.confirm3()
            }
          }
        })
      },
      confirm3() {
        const that = this
        let water = 0
        this.str = "court=" + this.court +
                   "&rid=" + sessionStorage.getItem('rid')+
                   "&name=" + this.name +
                   "&teamName=" + this.teamName +
                   "&phone=" + this.phone +
                   "&water=" + water +
                   "&referee=" + this.referee
        for(let i = 0;i< that.$parent.pickList.length;i++){
          that.str = that.str + "&ids=" + that.$parent.pickList[i]
        }
        for(let i = 0;i< that.free.length;i++){
          that.str = that.str + "&free=" + that.free[i]
        }
        this.$axios.post(that.$url+'secondfindOrder',this.str)
          .then(function(res){
            let data = res.data
            if(data.flag){
              that.showOrderForm =  false
              that.$axios.get(that.$url + 'createOrder?'+ that.str)
                .then(function(res){
                  let data = res.data;
                  if (data != null){
                    sessionStorage.setItem('ooO',data)
                    that.$router.push('/pay')
                  }
                })
            }else {
              that.showOrderForm =  false
              that.$parent.$refs.warn.warnText = '抱歉，场地已被其他用户预订'
              that.$parent.$refs.warn.showWarn = true
              setTimeout(function () {
                that.$parent.$refs.warn.showWarn = false
                that.reload()
              }, 1000);
            }
          })
      }
    }
  };
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
