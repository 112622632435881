<template>
  <div class="page">
    <div class="weui-msg">
      <div class="weui-msg__icon-area"><i class="weui-icon-warn weui-icon_msg"></i></div>
      <div class="weui-msg__text-area">
        <h2 class="weui-msg__title">操作失败</h2>
        <h2 class="weui-msg__title">请在微信中打开</h2>
        <p class="weui-msg__desc">请关注<span> 江湾云创信息枢纽 </span>微信公众号，并在微信公众号中打开</p>
        <p class="weui-msg__desc">或者重新进入<span> 江湾云创信息枢纽 </span>微信公众号下的 <span style="color: blue"> 球场预订 </span></p>
      </div>

      <div style="padding-right: 15px;padding-left: 15px">
        <img :src="score" width="100%" height="100%">
      </div>
    </div>
  </div>
</template>

<script>
  import score from '@/assets/image/扫码_搜索联合传播样式-标准色版.png'
  export default {
    name: 'wxWarn',
    data() {
      return {
        score: score,
      }
    }
  };
</script>

<style scoped>

</style>
