<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h2 v-html="html"></h2>
        <div class="weui-form-preview">
          <div class="weui-form-preview__hd">
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">实付金额</label>
              <em v-if="order.dmoney === 0" class="weui-form-preview__value">免费</em>
              <em v-else class="weui-form-preview__value">{{ order.dmoney }}元</em>
            </div>
          </div>
          <div class="weui-form-preview__bd">
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">商品</label>
              <span v-if="order.court === 'basketball'" class="weui-form-preview__value">室内篮球场</span>
              <span v-if="order.court === 'tennis'" class="weui-form-preview__value">室内网球场</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">订单号</label>
              <span class="weui-form-preview__value">{{ order.wxNo }}</span>
            </div>
            <div v-for="(ot,index) in orderTime" class="weui-form-preview__item">
              <label class="weui-form-preview__label">场次{{ index + 1 }}</label>
              <span
                class="weui-form-preview__value">{{ timestampTotime(ot.begintime) }}—{{ timestampTotime(ot.endtime) }}</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">总计时间</label>
              <span class="weui-form-preview__value">{{ order.hour }}小时</span>
            </div>
            <!--            <div class="weui-form-preview__item">-->
            <!--              <label class="weui-form-preview__label">联系人</label>-->
            <!--              <span class="weui-form-preview__value">{{order.name}}</span>-->
            <!--            </div>-->
            <!--            <div class="weui-form-preview__item">-->
            <!--              <label class="weui-form-preview__label">队伍名</label>-->
            <!--              <span class="weui-form-preview__value">{{order.teamName}}</span>-->
            <!--            </div>-->
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">手机号码</label>
              <span class="weui-form-preview__value">{{ order.phone }}</span>
            </div>
            <!--            <div class="weui-form-preview__item">-->
            <!--              <label class="weui-form-preview__label">全（半）场</label>-->
            <!--              <span class="weui-form-preview__value">{{type}}</span>-->
            <!--            </div>-->
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">订单金额</label>
              <span v-if="order.money === 0 " class="weui-form-preview__value">免费</span>
              <span v-else class="weui-form-preview__value">{{ order.money }}元</span>
            </div>
            <div id="e"
                 class="weui-form-preview__item"
                 style="display: none">
              <label class="weui-form-preview__label">场外消费</label>
              <span v-if="order.water === 1" class="weui-form-preview__value">纯净水(45元)</span>
              <span v-else class="weui-form-preview__value">无</span>

            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">会员优惠折扣</label>
              <span v-if="order.discount > 0" class="weui-form-preview__value">{{ order.discount }}折</span>
              <span v-else class="weui-form-preview__value">无优惠</span>
            </div>
            <div class="weui-form-preview__item">
              <label class="weui-form-preview__label">优惠券折扣</label>
              <span v-if="order.free != null" class="weui-form-preview__value">减免{{ order.free.length }}个场次</span>
              <span v-else class="weui-form-preview__value">无优惠</span>
            </div>
          </div>
        </div>
        <div v-if="order.dmoney !== 0" class="pay" @click="pay">
          <a class="weui-btn weui-btn_primary">立即支付</a>
        </div>
        <div v-else class="pay" @click="payFree">
          <a class="weui-btn weui-btn_primary">立即下单</a>
        </div>
        <div style="text-align: center;margin-top: 30px">
          <button class="btn btn-warning"
                  type="button"
                  @click="cancel2(true)">取消订单
          </button>
        </div>
        <warn ref="wn"></warn>
        <toast ref="tt"></toast>
      </div>
    </div>
  </div>
    <div id="dialogs">
      <div v-show="showCancel"  aria-hidden="true" aria-modal="true" class="js_dialog" role="dialog">
        <div class="weui-mask"></div>
        <div class="weui-dialog">
          <div class="weui-dialog__bd">您确定取消订单吗？</div>
          <div class="weui-dialog__ft">
            <a class="weui-dialog__btn weui-dialog__btn_default" role="button" @click="cancel2(false)">返回</a>
            <a class="weui-dialog__btn weui-dialog__btn_warn" role="button" @click="cancel" style="color: red">确定</a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Toast from '../components/modular/toast'

import Warn from '@/components/modular/warn'
import wx from 'weixin-js-sdk'

export default {
  name: 'Pay',
  data () {
    return {
      order: [],
      court: '',
      ooO: '',
      min: 0,
      sec: 0,
      appId: '',
      timeStamp: '',
      nonceStr: '',
      package: '',
      signType: '',
      paySign: '',
      rid: '',
      type: '全场',
      html: '剩余支付时间:&nbsp;&nbsp;',
      orderTime: [],
      showCancel: false
    }
  },
  components: {
    Toast,
    Warn
  },
  created () {
    this.rid = sessionStorage.getItem('rid')
    if (this.rid == null) {
      //错误页面
      this.$router.push('/wxWarn')
    } else {
      const that = this
      this.ooO = sessionStorage.getItem('ooO')
      if (this.ooO === 'null' || this.ooO == null) {
        //跳转错误页面
        this.$router.push('/wxWarn')
      } else {
        this.$axios.post(that.$url + 'findOrder', 'ooO=' + this.ooO)
          .then(function (res) {
            let order = res.data
            console.log(order)
            if (order.wxNo == null) {
              that.$refs.wn.showWarn = true
              that.$refs.wn.warnText = '订单已过期'
              setTimeout(function () {
                that.$refs.wn.showWarn = false
                that.$router.push('/')
              }, 2000)
            } else {
              that.order = order
              that.court = order.court
              let time = order.time
              let now = Date.parse(new Date())
              let diffTime = that.calculateDiffTime(time, now)
              that.min = diffTime[0]
              that.sec = diffTime[1]
              // setInterval("getCountdown()", 1000);
              that.getCountdown()
              that.$axios.get(that.$url + 'findOrderTime?ooO=' + that.ooO)
                .then(function (res) {
                  res.data.sort(function (a, b) {
                    return a.begintime - b.begintime
                  })
                  // console.log(res.data)
                  that.orderTime = res.data
                })
            }
          })
      }
    }
  },
  mounted () {
    this.min = 14
    this.sec = 60
  },
  methods: {
    timestampTotime (time) {
      if (time != null) {
        let date = new Date(time + 8 * 3600 * 1000)
        return date.toJSON().substr(0, 16).replace('T', ' ').replace(/-/g, '.')
      }
    },
    pay () {
      const that = this
      this.$axios.post(that.$url + 'wxpay', 'ooO=' + this.ooO)
        .then(function (res) {
          let result = res.data
          if (result != null) {
            if (typeof WeixinJSBridge === 'undefined') {
              if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(result), false)
              } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(result))
                document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(result))
              }
            } else {
              that.onBridgeReady(result)
            }
          } else {
            that.$refs.wn.warnText = '支付时间已过，请重新下单'
            that.$refs.wn.showWarn = true
            setTimeout(function () {
              that.$refs.wn.showWarn = false
              // if (that.order.court === 'basketball') {
              //   that.$router.push('/basketball');
              // } else {
              that.$router.push('/tennis')
              // }
            }, 2000)
          }
        })
    },
    onBridgeReady (result) {
      const that = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          'appId': result.appId,  //公众号名称，由商户传入
          'timeStamp': result.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          'nonceStr': result.nonceStr,  //支付签名随机串，不长于 32 位
          'package': result.package,//统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          'signType': result.signType,  //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          'paySign': result.paySign //支付签名
        },
        function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            alert('支付成功！')
            that.$router.push('/tennis')
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            alert('支付失败！')
          }
        })
    },
    wexinPay () {
      const that = this
      console.log('发起微信支付')
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: that.appId, // 必填，公众号的唯一标识
        timestamp: that.timestamp, // 必填，生成签名的时间戳
        nonceStr: that.nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
      wx.ready(function () {
        wx.chooseWXPay({
          timestamp: that.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: that.nonceStr, // 支付签名随机串，不长于 32 位
          package: that.packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: that.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: that.paySign, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
            alert('成功')
          },
          fail: function (res) {
            //失败回调函数
          }
        })
      })
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        alert('config信息验证失败')
      })
    },
    payFree () {
      const that = this
      that.$axios.post(that.$url + 'payFree', 'ooO=' + that.ooO)
        .then(function (res) {
          if (res.data === true) {
            that.$refs.tt.showToast = true
            setTimeout(function () {
              that.$refs.tt.showToast = false
              // if (that.order.court === 'basketball') {
              //   that.$router.push('Basketball');
              // } else {
              that.$router.push('Tennis')
              // }
            }, 1400)
          }
        })
    },
    cancel2 (show) {
      this.showCancel = show
    },
    cancel () {
      const that = this
      that.showCancel = false
      this.$axios.get(that.$url + 'usercancel?ooO=' + this.ooO)
        .then(function (res) {
          if (res.data === true) {
            that.min = 14
            that.sec = 60
            that.$router.push('/')
          } else {
            that.$refs.wn.warnText = '系统出现错误'
            that.$refs.wn.showWarn = true
            setTimeout(function () {
              that.$refs.wn.showWarn = false
            }, 1000)
          }
        })
    },
    calculateDiffTime (start_time, end_time) {
      let startTime = 0, endTime = 0
      if (start_time < end_time) {
        startTime = start_time
        endTime = end_time
      } else {
        startTime = end_time
        endTime = start_time
      }
      let timeDiff = 900000 - (endTime - startTime)
      let yushu = Math.floor(timeDiff % 60000)
      let second = Math.round(yushu / 1000)
      timeDiff = timeDiff - yushu
      let minute = Math.floor(timeDiff / 60000)
      return [minute, second]
    },
    getCountdown () {
      const that = this
      if (this.min === 0 && this.sec === 0) {  //倒计时结束
        this.$router.push('/tennis')
      } else if (this.min >= 0) {
        if (this.sec > 0) {
          that.sec--
        } else if (this.sec === 0) {
          that.min--
          that.sec = 59
        }
      }
      that.html = '剩余支付时间:&nbsp;&nbsp;<span style=\'color: red\'>' + this.min + ':' + (this.sec > 9 ? this.sec : '0' + this.sec) + '</span>'
      setTimeout(function () {
        that.getCountdown()
      }, 1000)
    }
  }
}
</script>

<style scoped>
.pay {
  margin-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}
</style>
