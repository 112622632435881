<template>
  <!--BEGIN toast-->
  <transition name="fade">
    <div v-show="showToast">
      <div class="weui-mask_transparent"></div>
      <div class="weui-toast">
        <i class="weui-icon-success-no-circle weui-icon_toast"></i>
        <p class="weui-toast__content" id="toastTxt">已完成</p>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'toast',
    data () {
      return {
        showToast: false
      }
    }
  }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>
