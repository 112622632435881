<template>
  <div class="container-fluid">
    <div class="row" style="padding-left: 1%;padding-right: 1%">
      <div class="col-xs-12">
        <div class="page__hd" style="margin-bottom: 5vh">
          <h1 class="page__title" style="text-align: center">待使用的优惠券</h1>
<!--          <p class="page__desc">对话框</p>-->
        </div>
        <div class="weui-cells" v-for="care in clist" >
          <a aria-labelledby="js_cell_itl1_hd js_cell_itl1_bd js_cell_itl1_ft" class="weui-cell weui-cell_access weui-cell_example" @click="booking">
            <span class="weui-cell__hd" id="js_cell_itl1_hd" aria-hidden="true">
              <img src="../assets/image/dy.png" alt="头像" style="width: 20px; margin-right: 16px; display: block;">
            </span>
            <span class="weui-cell__bd" id="js_cell_itl1_bd" aria-hidden="true">
                    <span>抖音团购单次标准券</span>
                </span>
            <span class="weui-cell__ft" id="js_cell_itl1_ft" aria-hidden="true">点击预订场次</span>
          </a>
        </div>
      </div>
      <div class="col-xs-12" style="text-align: center;margin-top: 5vh">
        <button class="btn btn-warning" style="width: 30vw;height: 8vw;font-size: medium;font-weight: bolder" type="button" @click="back" >返回</button>
      </div>
    </div>
  </div>
</template>
<script>
import Warn from '@/components/modular/warn.vue'
import Foot from '@/views/foot.vue'
import Loading from '@/components/modular/loading.vue'

export default {
  name: 'careList',
  components: {
    Warn,
    Foot,
    Loading
  },
  data () {
    return {
      clist: [],
      rid: sessionStorage.getItem('rid'),
    }
  },
  created () {
    const that = this;
    this.$axios.post(this.$url + 'dy/findDyCardByRid', 'rid=' + this.rid)
      .then(function(res) {
        // alert(res)
        that.clist = res.data;
      });
  },
  methods: {
    back() {
      this.$router.push('/user')
    },
    booking() {
      this.$router.push('/tennis')
    }
  }
}
</script>
<style scoped>

</style>
