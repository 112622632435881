<template>
  <!--BEGIN dialog4-->
  <div class="js_dialog" v-show="showFree">
    <div class="weui-mask"></div>
    <div class="weui-dialog">
<!--      <div class="weui-dialog__hd"><strong class="weui-dialog__title" id="js_title1">请选择优惠券</strong></div>-->
      <h4 style="text-align: center;font-weight: bold">请选择优惠券</h4>
      <div class="weui-dialog__bd" style="margin-bottom: 5px;height: 12vh;overflow-y: auto">
        <div class="weui-cells weui-cells_checkbox">
        <label class="weui-cell weui-cell_active weui-check__label" :for="'S'+item.id" v-for="item in FreeList" :key="item.id">
          <div class="weui-cell__hd">
            <input type="checkbox" class="weui-check" :name="item.id" :value="item.id" :id="'S'+item.id" v-model="uList" />
            <i class="weui-icon-checked"></i>
          </div>
          <div class="weui-cell__bd">
            <p>抖音30分钟单场次优惠券</p>
          </div>
        </label>
        </div>
      </div>
      <div class="weui-dialog__ft">
        <a  @click="back1"
           class="weui-dialog__btn weui-dialog__btn_default confirm"
            name="1">上一步</a>
        <a  @click="confirmFree()"
           class="weui-dialog__btn weui-dialog__btn_primary confirm"
            name="1">下一步</a>
      </div>
    </div>
  </div>
  <!--END dialog4-->
  <div role="alert"  v-show="sw">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
      <i class="weui-icon-warn weui-icon_toast"></i>
      <p class="weui-toast__content">选择优惠券数量过多</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'showFree',
  inject:['reload'],
  data() {
    return {
      showFree: false,
      FreeList: [],
      freeText: '',
      uList: [],
      pl2 : 0,
      sw: false,
    }
  },
  methods: {
    confirmFree() {
      const that = this
      console.log(this.uList)
      console.log(this.pl2)
      if (that.pl2 < that.uList.length) {
        that.sw = true
        setTimeout(function () {
          that.sw = false
        }, 1000)
      } else {
        that.$parent.free = this.uList
        that.$parent.confirm3()
      }
    },
    back1() {
      this.showFree = false
      this.$parent.showOrderForm = true
      this.$parent.first = true
    }
  }
}
</script>

<style scoped>

</style>
