<template>
  <div class="container-fluid">
    <div class="row">
      <ul class="nav nav-tabs">
        <li class="active"><a data-toggle="tab"
                              href="#home">VIP名单</a></li>
        <li class="dropdown"><a data-toggle="tab"
                                href="#menu1">折扣</a></li>
        <li class="dropdown"><a data-toggle="tab"
                                href="#menu3">免费</a></li>
      </ul>
      <div class="tab-content">
        <div id="home"
             class="tab-pane fade in active">
          <div v-for="(role) in vipList" v-show="role.administrators === false"
               class="weui-cell weui-cell_active weui-cell_switch">
            <div class="weui-cell__bd">
              <img :src="role.wxUser.headimgurl"
                   class='img-circle IMG' style="margin-right: 10px">
              <span>{{ role.wxUser.nickname }} </span>
            </div>
            <div class="weui-cell__ft">
              <label :for="'switchCP' + role.rid"
                     class="weui-switch-cp">
                <input :id="'switchCP' + role.rid"
                       class="weui-switch-cp__input"
                       type="checkbox"
                       @click="updateVip(role.rid)" v-bind:checked="role.vip"/>
                <div class="weui-switch-cp__box"></div>
              </label>
            </div>
          </div>
        </div>
        <div id="menu1"
             class="tab-pane fade ">
          <h5>
            <mark style="color: red">提示：只显示VIP用户</mark>
          </h5>
          <ul class="nav nav-pills">
            <li class="active"><a data-toggle="tab"
                                  @click.native="this.court = 'basketball'">篮球场</a></li>
            <li><a data-toggle="tab"
                   @click.native="this.court = 'tennis'">网球场</a></li>
          </ul>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>头像</th>
              <th class="col-xs-4">昵称</th>
              <th>折扣</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(role) in discountList" v-show="role.administrators === false">
              <td><img :src="role.wxUser.headimgurl"
                       class='img-circle IMG'></td>
              <td>{{ role.wxUser.nickname }}</td>
              <td v-show="court === 'basketball'">{{ getDisount(role.bdiscount) }}</td>
              <td v-show="court === 'tennis'">{{ getDisount(role.tdiscount) }}</td>
              <td>
                <button type="button"
                        class="btn btn-primary"
                        @click="showDiscount(role.rid)">修改
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div id="menu3"
             class="tab-pane fade">
          <ul class="nav nav-pills">
            <li class="active"><a data-toggle="tab"
                                  @click="this.court = 'basketball'">篮球场</a></li>
            <li><a data-toggle="tab"
                   @click="this.court = 'tennis'">网球场</a></li>
          </ul>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>头像</th>
              <th class="col-xs-4">昵称</th>
              <th>次数</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(role) in freeList" v-show="role.administrators === false">
              <td><img :src=" role.wxUser.headimgurl" class='img-circle IMG'></td>
              <td>{{ role.wxUser.nickname }}</td>
              <td v-show="court === 'basketball'">{{getFreeType(role.bfree,role.bfreetype)}}</td>
              <td v-show="court === 'tennis'">{{getFreeType(role.tfree,role.tfreetype)}}</td>
              <td>
                <button type='button'
                        class='btn btn-info'
                        @click="showFree(role.rid)">修改
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="col-xs-12"
             style="text-align: center;margin-top: 30px;margin-bottom: 30px">
          <button type="button"
                  class="btn btn-warning"
                  @click="this.$router.push('/user')">返回上一页
          </button>
        </div>
        <div class="js_dialog"
             v-show="discountDislog">
          <div class="weui-mask"></div>
          <div class="weui-dialog">
            <div class="weui-dialog__hd"><strong class="weui-dialog__title">输入折扣</strong></div>
            <div class="weui-dialog__bd">
              <input type="tel"
                     v-model="discountIn"
                     placeholder="请输入折扣"
                     maxlength="1">
            </div>
            <div class="weui-dialog__ft">
              <a @click="this.discountDislog = false"
                 class="weui-dialog__btn weui-dialog__btn_default">取消</a>
              <a @click="updateDiscount()"
                 class="weui-dialog__btn weui-dialog__btn_primary">确认</a>
            </div>
          </div>
        </div>
        <div class="js_dialog"
             v-show="freeDislog">
          <div class="weui-mask"></div>
          <div class="weui-dialog">
            <div class="weui-dialog__hd"><strong class="weui-dialog__title">输入次数</strong></div>
            <div class="weui-dialog__bd">
              周期
              <select v-model="freeType">
                <option value="1">每周</option>
                <option value="2">每月</option>
              </select>
              次数
              <select v-model="free">
                <option :value="n -1" v-for="(n) in 10">{{n -1}}次</option>
              </select>
            </div>
            <div class="weui-dialog__ft">
              <a @click="this.freeDislog = false"
                 class="weui-dialog__btn weui-dialog__btn_default">取消</a>
              <a @click="updateFree()"
                 class="weui-dialog__btn weui-dialog__btn_primary">确认</a>
            </div>
          </div>
        </div>
        <toast ref="tt"></toast>
        <warn ref="wn"></warn>
        <div class="js_dialog"
             id="androidDialog2"
             style="display: none;">
          <div class="weui-mask"></div>
          <div class="weui-dialog weui-skin_android">
            <div class="weui-dialog__bd"
                 id="androidDialog2Text"></div>
            <div class="weui-dialog__ft">
              <a @click="cancel1()"
                 class="weui-dialog__btn weui-dialog__btn_default">取消</a>
              <a class="weui-dialog__btn weui-dialog__btn_primary">确定</a>
            </div>
          </div>
        </div>
        <loading ref="lg"></loading>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from '@/components/modular/loading'
  import Warn from '@/components/modular/warn'
  import Toast from '@/components/modular/toast'

  export default {
    name: 'updateRole',
    components: {
      Toast,
      Warn,
      Loading
    },
    data () {
      return {
        freeType: 1,
        free: 0,
        court: 'basketball',
        discountIn: '',
        vipList: {},
        discountList: {},
        freeList: {},
        discountDislog: false,
        freeDislog: false,
        rid: 0,
        updateId: ''
      }
    },
    created () {
      const that = this
      this.rid = sessionStorage.getItem('rid')
      if (this.rid == null) {
        this.$router.push('/wxWarn')
      } else {
        this.$axios.post(that.$url + 'findAdministratorsOfRole', 'rid=' + this.rid)
          .then(function (res) {
            let data = res.data
            if (data === false) {
              that.$router.push('/wxWarn')
            } else {
              that.$axios.post(that.$url + 'findVipOfRole', 'rid=' + that.rid)
                .then(function (res) {
                  that.discountList = res.data
                })
              that.$axios.post(that.$url + 'findAllOfRole', 'rid=' + that.rid)
                .then(function (res) {
                  that.vipList = res.data
                  that.freeList = res.data
                })
            }
          })
      }
    },
    mounted () {
    },
    methods: {
      getDisount (disount) {
        if (disount !== 0) {
          return disount + '折'
        } else {
          return '无优惠'
        }
      },
      showDiscount (rid) {
        this.updateId = rid
        this.discountDislog = true
      },
      getFreeType (number, type) {
        if (number > 0) {
          if (type === 1) {
            return '每周' + number + '次'
          }
          if (type === 2) {
            return '每月' + number + '次'
          }
        } else {
          return '无次数'
        }

      },
      showFree (rid) {
        this.freeDislog = true
        this.updateId = rid
      },
      findDiscountOfRole () {
        const that = this
        this.$axios.post(that.$url + 'findVipOfRole', 'rid=' + this.rid)
          .then(function (res) {
            let data = res.data
            if (data != null) {
              that.discountList = data
            }
          })
      },
      findVipOfRole () {
        const that = this
        that.$axios.post(that.$url + 'findAllOfRole', 'rid=' + that.rid)
          .then(function (res) {
            let data = res.data
            if (data != null) {
              that.vipList = data
              that.freeList = data
            }
          })
      },
      updateDiscount () {
        const that = this
        this.discountDislog = false
        if (this.discountIn > 0) {
          this.$axios.post(that.$url + 'updateDiscoutOfRole', 'id=' + this.updateId + '&discount=' + this.discountIn + '&rid=' + this.rid + '&court=' + this.court)
            .then(function (res) {
              let data = res.data
              if (data === true) {
                that.$refs.tt.showToast = true
                setTimeout(function () {
                  that.$refs.tt.showToast = false
                  that.findDiscountOfRole()
                }, 1400)
              } else {
                that.$refs.wn.warnText = '更新失败'
                that.$refs.wn.showWarn = true
                setTimeout(function () {
                  that.$refs.wn.showWarn = false
                }, 1400)
              }
            })
        } else {
          that.$refs.wn.warnText ='输入有误，请重新输入'
          that.discountDislog = false
          that.$refs.wn.showWarn = true
          setTimeout(function () {
            that.$refs.wn.showWarn = false
            that.discountDislog = true
          }, 1400)
        }
      },
      updateFree () {
        const that = this
        this.freeDislog = false
        this.$axios.post(that.$url + 'updateFreeOfRole', 'rid=' + this.rid + '&id=' + this.updateId + '&court=' + this.court + '&free=' + this.free + '&type=' + this.freeType)
          .then(function (res) {
            let data = res.data
            if (data === true) {
              that.$refs.tt.showToast = true
              setTimeout(function () {
                that.$refs.tt.showToast = false
                that.findVipOfRole()
              }, 1400)
            } else {
              that.$refs.wn.warnText = '更新失败'
              that.$refs.wn.showWarn = true
              setTimeout(function () {
                that.$refs.wn.showWarn = false
              }, 1400)
            }
          })
      },
      updateVip (id) {
        const that = this
        let checked = document.getElementById('switchCP' + id).checked
        that.$refs.lg.showLoad = true
        this.$axios.post(that.$url + 'updateVipOfRole', 'rid=' + this.rid + '&vip=' + checked + '&id=' + id)
          .then(function (res) {
            that.$refs.lg.showLoad = false
            let data = res.data
            if (data) {
              that.$refs.tt.showToast = true
              setTimeout(function () {
                that.$refs.tt.showToast = false
                that.findVipOfRole()
                that.findDiscountOfRole()
              }, 1400)
            } else {
              that.$refs.wn.warnText = '出现错误'
              that.$refs.wn.showWarn = true
              setTimeout(function () {
                that.$refs.wn.showWarn = false
              }, 1400)
            }
          })
      }
    }
  }
</script>

<style scoped>
  .IMG {
    width: 30px;
    height: 30px;
  }
</style>
