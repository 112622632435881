<template>
  <div class="container-fluid"
       style="overflow-y: auto;height: calc(100vh); ">
    <div class="row">
      <div class="row"
           v-bind:style="head">
        <div class="col-xs-4"
             style="height:100px;margin-top: 5%;padding-right: 0;">
          <div style="width: 76px;padding-left: 15px">
            <img  :src="headimgurl" id="headimg">
          </div>
        </div>
        <div class="col-xs-8"
             style="height:110px;margin-top: 5%;padding-left: 25px">
          <p style="font-size:24px;color:white">{{nickname}}</p>
          <div id="administrators" v-if="admin">超级管理员</div>
          <div id="vip" v-else-if="vip && admin === false">会员用户</div>
          <div id="ordinary" v-else-if="admin === false|| vip === false">普通用户</div>
        </div>
        <div class="col-xs-12">
          <div class="col-xs-3 colt">
            <p style="color: gold">{{consumed}}</p>
            <p style="font-size: small">累计消费</p>
          </div>
          <div class="col-xs-3 colt">
            <p style="color: red">{{number}}</p>
            <p style="font-size: small">消费次数</p>
          </div>
          <!--<div class="col-xs-3 colt">-->
            <!--<p style="color: white">{{bDiscount === 0?'正价':bDiscount + '折'}}</p>-->
            <!--<p>篮球场<br>优惠</p>-->
          <!--</div>-->
          <div class="col-xs-4 colt">
            <p style="color: white">{{tDiscount === 0?'正价':tDiscount + '折'}}</p>
            <p style="font-size: small">网球场优惠</p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xs-12"
           style="margin-top: 15px">
        <grids></grids>
      </div>
      <foot></foot>
    </div>
  </div>
</template>

<script>
  import Grids from '@/components/modular/grids';
  import Foot from '@/views/foot';
  export default {
    name: 'User',
    components: { Grids, Foot },
    data()  {
      return {
        // rid: sessionStorage.getItem('rid')
        rid: '',
        nickname: '用户',
        consumed: '',
        number: 0,
        bDiscount: 0,
        tDiscount: 0,
        vip: false,
        admin: false,
        headimgurl: '',
        head: {
          backgroundImage: 'linear-gradient(to right, #7A88FF, #7AFFAF)',
          padding: '10px',
          margin: 0,
        }
        // user: []
      };
    },
    created () {
      const that = this
      this.rid = sessionStorage.getItem('rid')
      this.$axios.post(this.$url + 'findRole','rid='+this.rid)
      .then(function(res){
        let data = res.data
        if(data != null){
          that.headimgurl = data.headimgurl
          that.nickname = data.nickname
          that.consumed = data.consumed
          that.number = data.number
          that.bDiscount = data.bdiscount
          that.tDiscount = data.tdiscount
          that.admin = data.administrators
          that.vip = data.vip
          if (that.vip){
            that.head = {
              backgroundImage: 'none',
              backgroundColor: 'rgb(48,48,48)',
              color: 'white',
              margin: 0
            }
          }
        }
      });
    }
  };
</script>

<style scoped>
  body {
    width: 100vw;
  }

  .colt{
    text-align: center;
    padding-left: 1px;
    padding-right: 1px;
  }

  /*#number{*/
  /*margin-right: 10px;*/
  /*padding-right: 8px;*/
  /*margin-left: 10px;*/
  /*padding-left: 8px;*/
  /*border-left: 2px solid white;*/
  /*border-right: 2px solid white;*/
  /*}*/
  #headimg {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  #vip {
    /*font-weight: bold;*/
    text-align: center;
    width: 80px;
    color: #66512c;
    background-image: linear-gradient(to right, yellow, #c87d2f);
    border-radius: 20px;
  }

  #ordinary {
    font-weight: bold;
    text-align: center;
    color: white;
    width: 80px;
    background-image: linear-gradient(to right, #00b7ee, #7A88FF);
    border-radius: 20px;
  }
  #administrators{
    font-weight: bold;
    text-align: center;
    color: yellow;
    width: 90px;
    background-image: linear-gradient(to right,red,orange);
    border-radius: 20px;
  }
</style>
