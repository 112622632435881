<template>
  <!-- loading toast -->
  <div v-show="showLoad">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
            <span class="weui-primary-loading weui-icon_toast">
              <span class="weui-primary-loading__dot"></span>
            </span>
      <p class="weui-toast__content">加载中</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  data() {
    return {
      showLoad: false,
    }
  },
}
</script>

<style scoped>

</style>
