<template>
  <!--BEGIN toast-->
  <transition name="fade">
  <div id="warnToast" v-show="showWarn">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
      <i class="weui-icon-warn weui-icon_toast"></i>
      <p class="weui-toast__content">{{warnText}}</p>
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  name: 'warn',
  data() {
    return {
      showWarn: false,
      warnText: '',
    }
  }
}
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>
