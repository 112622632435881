import { createRouter, createWebHistory } from 'vue-router'
import basketball from '@/views/basketball'
import user from '@/views/user'
import pay from '@/views/pay'
import orderList from '@/views/orderList'
import order from '@/views/order'
import wxWarn from '@/views/wxWarn'
import updatePrice from '@/views/updatePrice'
import updateRole from '@/views/updateRole'
import home from '@/views/home'
import tennis from '@/views/tennis'
import dy from '@/views/dy'
import careList from '@/views/careList.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    title: '光影文旅',
    component: home
  },
  {
    path: '/basketball',
    name: 'Basketball',
    title: '室内篮球场',
    component: basketball
  },
  {
    path: '/tennis',
    name: 'Tennis',
    title: '室内网球场',
    component: tennis
  },
  {
    path: '/user',
    name: 'User',
    title: '个人中心',
    component: user
  },
  {
    path: '/pay',
    name: 'Pay',
    title: '支付订单',
    component: pay
  },
  {
    path: '/orderList',
    name: 'OrderList',
    title: '我的订单',
    component: orderList
  },
  {
    path: '/dy',
    name: 'Dy',
    title: '抖音',
    component: dy
  },
  {
    path: '/CareList',
    name: 'CareList',
    title: '卡包',
    component: careList
  },
  {
    path: '/order',
    name: 'Order',
    title: '订单详情',
    component: order
  },
  {
    path: '/wxWarn',
    name: 'WxWarn',
    component: wxWarn
  },
  {
    path: '/updatePrice',
    name: 'UpdatePrice',
    title: '修改价格',
    component: updatePrice
  },
  {
    path: '/updateRole',
    name: 'UpdateRole',
    title: '修改用户账户',
    component: updateRole
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
