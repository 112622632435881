<template>
  <!--END dialog3-->
  <div class="js_dialog"
       v-show="show">
    <div class="weui-mask"
         style="z-index: 3000"></div>
    <div class="weui-dialog">
      <!--<button @click="show = false"-->
              <!--type="button"-->
              <!--class="close"-->
              <!--aria-hidden="true"-->
              <!--style="position: absolute;left: 90%;top:0">&times;-->
      <!--</button>-->
      <div style="border-radius: 20px;margin-bottom: 5px;padding-left: 10px;padding-right: 10px;">
        <h4 style="font-weight: bold">免责声明</h4>
        <div class="textIntro"
             v-html="text"
             style="height:350px;overflow-y:auto;text-align: left"></div>
      </div>
      <div @click="hideStatement()">
        <button type="button"
                class="btn btn-info btn-lg btn-block">
          我已阅读并同意
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Text from '@/assets/txt/Statement.txt'
export default {
  name: 'statement',
  data() {
    return {
      show: false,
      text: Text,
    }
  },
  mounted () {
    const read = sessionStorage.getItem('read')
    if (read == null) {
      this.show = true
    }
  },
  methods: {
    hideStatement () {
      this.show = false
      sessionStorage.setItem('read', true)
      this.$parent.findCacheOrder(false)
    }
  }
}
</script>

<style scoped>

</style>
