<template>
  <div class="weui-grids"
       id="grids">
    <a class="weui-grid"
       @click="jump('OrderList')">
      <div class="weui-grid__icon">
        <img :src="orderIMG"
             alt="">
      </div>
      <p class="weui-grid__label">订单</p>
    </a >
    <a class="weui-grid"
       @click="jump('CareList')">
      <div class="weui-grid__icon">
        <img :src="careListIMG"
             alt="">
      </div>
      <p class="weui-grid__label">卡包</p>
    </a >
    <a class="weui-grid" v-show="admin"
       @click="jump('Dy')">
      <div class="weui-grid__icon">
        <img :src="dyIMG"
             alt="">
      </div>
      <p class="weui-grid__label">抖音核销</p>
    </a >
    <a class="weui-grid" v-show="admin"
       @click="jump('UpdatePrice')">
      <div class="weui-grid__icon">
        <img :src="priceIMG"
             alt="">
      </div>
      <p class="weui-grid__label">修改场地价格</p>
    </a >
    <a class="weui-grid" v-show="admin"
       @click="jump('UpdateRole')">
      <div class="weui-grid__icon">
        <img :src="priceIMG"
             alt="">
      </div>
      <p class="weui-grid__label">修改账户信息</p>
    </a >
  </div>
</template>

<script>
  import orderIMG from '@/assets/image/order.png';
  import priceIMG from '@/assets/image/price.png';
  import dyIMG from '@/assets/image/dy.png';
  import careListIMG from '@/assets/image/care.png';
  export default {
    name: 'grids',
    data()  {
      return {
        admin: false,
        rid: 0,
        orderIMG: orderIMG,
        priceIMG: priceIMG,
        careListIMG: careListIMG,
        dyIMG: dyIMG,
      };
    },
    mounted () {
      const that = this
      this.rid = sessionStorage.getItem('rid')
      if (this.rid != null){
        this.$axios.post(that.$url + 'findAdministratorsOfRole','rid='+this.rid)
          .then(function(res){
            // console.log(res)
            that.admin = res.data
          })
      }
    },
    methods: {
      jump(name) {
          this.$router.push({
            name: name
          });
      }
    }
  };
</script>

<style scoped>
</style>
