<template>
  <div>
    <div class="container-fluid"
         style="overflow: auto;height: calc(100vh)">
      <div class="row">
        <p>点击拨打客服热线：<a href="tel:18029680338"
                       style="font-weight: bold;color: red">18029680338</a>,<a href="tel:13326826235"
                                                                               style="font-weight: bold;color: red">13326826235</a>
        </p>
        <h4 id="now"
            style="text-align: center" v-html="timeText"></h4>
        <div style="margin-left: 15px">
          <button type="button"
                  class="btn"
                  style="color: #fff;background-color: #5cb85c;border-color: #4cae4c;" disabled></button>
          <span>已出售</span>
          <button type="button"
                  class="btn"
                  style="color: #fff;background-color: #337ab7;border-color: #2e6da4;" disabled></button>
          <span>已选中</span>
          <button type="button"
                  class="btn btn-danger" disabled></button>
          <span>已售完</span>
          <button type="button"
                  class="btn btn-default" disabled></button>
          <span>可购买</span>
        </div>
        <h5 style="text-align: center;">
          <button type="button"
                  style="border-radius: 10px;width: 60px;height: 25px;color: #fff;;background-color: #337ab7;border-color: #2e6da4;font-weight: bold"
                  disabled>全场
          </button>
        </h5>
        <div id="ballChoose">
          <div id="ballRow">
            <div style="position: relative;margin-top: 14px;">
              <div class="datef" v-for="(val,index) in dateList">
                <div style="position: absolute;left: -33px;top: -8px;" v-if="index === 0">
                  <p>9:00</p>
                </div>
                <span class="date" v-html="val.date"></span>
                <button :id="(index+1)+'1'"
                        class="btn btn-default btn-sm "
                        :name="index + 1"
                        v-bind:class="{'btn-success' : val.ball != null}"
                        v-bind:disabled="admin === true?false:val.ball != null"
                        @click="val.ball == null?cpick($event):admin === true?val.ball === 0?paying:showOrder(val.ball):null">
                  {{val.ball == null?'￥'+val.price:admin === true?'点击查看':'已出售'}}
                </button>
              </div>
              <p style="position: absolute;left: -40px;top: 20px;color:black;margin: 0">10:00</p>
              <button v-for="(val,index) in ballList"
                      :id="(index+1)+'2'"
                      :name="index + 1"
                      v-bind:class="{'btn-success' : val.ball != null}"
                      v-bind:disabled="admin === true?false:val.ball != null"
                      class="btn btn-default btn-sm"
                      @click="val.ball == null?cpick($event):admin === true?val.ball === 0?paying:showOrder(val.ball):null">
                {{val.ball == null?'￥'+val.price:admin === true?'点击查看':'已出售'}}
              </button>
            </div>
            <div style="position: relative;" v-for="x in 13">
              <div class="time">
                <p>{{x+10}}:00</p>
              </div>
              <button v-if="x < 13"
                      v-for="a in 7"
                      :id="a +''+ (x+2)"
                      class="btn btn-default btn-sm"
                      v-bind:class="{'btn-success' : getBall(x+1,a-1) != null}"
                      v-bind:disabled="getBall(x+1,a-1) != null?admin === false:false"
                      @click="getBall(x+1,a-1) == null?cpick($event):admin === true?getBall(x+1,a-1) === 0?paying:showOrder(getBall(x+1,a-1)):cpick">
                {{getPrice(x+1,a-1)}}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xs-12"
             style="text-align: center;margin-bottom: 60px">
          <a @click="cancel()"
             class="weui-btn weui-btn_warn">全选取消</a>
          <a @click="submit()"
             class="weui-btn weui-btn_primary">确定提交</a>
        </div>
        <order-form ref="of"></order-form>
        <tips ref="tp"></tips>
        <loading ref="load"></loading>
        <show-order ref="so"></show-order>
        <statement></statement>
        <toast ref="toast"></toast>
        <warn ref="warn"></warn>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
  import '@/assets/weUIv2.4.0/style/weui.css'
  import Loading from '@/components/modular/loading'
  import ShowOrder from '@/components/modular/showOrder'
  import Toast from '@/components/modular/toast'
  import Warn from '@/components/modular/warn'
  import Statement from '@/components/modular/statement'
  import Foot from '@/views/foot'
  import Tips from '@/components/modular/tips'
  import OrderForm from '@/components/modular/orderForm'

  // eslint-disable-next-line no-unused-lets
  export default {
    name: 'Basketball',
    // eslint-disable-next-line vue/no-unused-components
    components: {
      OrderForm,
      Tips,
      Statement,
      Warn,
      Toast,
      ShowOrder,
      Loading,
      Foot
    },
    data () {
      return {
        // rid: sessionStorage.getItem('rid')
        dayList: [],
        btnPick: false,
        pickList: [],
        dateList: [],
        priceList: [],
        ballList: [],
        rid: '',
        ball: [],
        year: 0,
        month: 0,
        day: 0,
        hour: 0,
        min: 0,
        sec: 0,
        admin: false,
        timeText: '<span style=\'font-weight: bold\'>当前时间:</span>&nbsp;&nbsp;'
      }
    },
    created () {
      const that = this
      if (sessionStorage.getItem('rid') === null) {
        that.$router.push('/')
      }else {
        that.rid = sessionStorage.getItem('rid')
      }
      that.findBall()
    },
    mounted () {
      document.body.onselectstart = document.body.ondrag = function () {
        return false
      }
      this.timeCount()
    },
    methods: {
      paying() {
        this.$refs.warn.warnText = '用户正在支付'
        this.$refs.warn.showWarn = true
        setTimeout(function () {
          this.$refs.warn.showWarn = false
        },1400)
      },
      findBall() {
        const that = this
        this.$axios.post(this.$url + 'basketballall')
          .then(function (res) {
            that.ball = res.data
            that.$axios.post(that.$url + 'findAdministratorsOfRole', 'rid=' + that.rid)
              .then(function (resf) {
                that.admin = resf.data
                that.dateList = []
                that.ballList = []
                that.priceList = []
                for (let i = 0; i < that.ball.length; i++) {
                  // let basketball = data[i];
                  let year = that.ball[i].year
                  let month = that.ball[i].month
                  let day = that.ball[i].day
                  let date = month + '月' + day + '日<br>' + that.findweek(year, (month - 1), day)
                  let price = that.ball[i].price
                  let ball = that.ball[i]
                  that.dateList.push({
                    date: date,
                    price: price.one,
                    ball: ball.one
                  })
                  that.ballList.push({
                    price: price.two,
                    ball: ball.two
                  })
                  that.priceList.push(price)
                }
              })
          })
      },
      flag() {
        const that = this
        let flag = true
        for(let x in  this.dayList) {
          let day = this.dayList[x]
          let index = 0
          for (let i in that.pickList){
            let date = that.pickList[i].substring(0,1)
            if (date === day) {
              index++
            }
          }
          if (index === 1) {
            flag = false
            break
          }
        }
        return flag
      },
      showOrder (oid) {
        const that = this
        this.$axios.post(that.$url + 'findOrderByID', 'OID=' + oid + '&RID=' + this.rid)
          .then(function (res) {
            let order = res.data
            that.$refs.so.order = order
            that.$refs.so.oid = order.oid
            that.$refs.so.imgUrl = order.wxUser.headimgurl
            that.$refs.so.nickname = order.wxUser.nickname
            that.$refs.so.showOrder = true
          })
      },
      getBall (number, day) {
        const that = this
        const arr = new Array()
        arr[0] = 'one'
        arr[1] = 'two'
        arr[2] = 'three'
        arr[3] = 'four'
        arr[4] = 'five'
        arr[5] = 'six'
        arr[6] = 'seven'
        arr[7] = 'eight'
        arr[8] = 'nine'
        arr[9] = 'ten'
        arr[10] = 'eleven'
        arr[11] = 'twelve'
        arr[12] = 'thirteen'
        arr[13] = 'fourteen'
        let ball = that.ball[day]
        for (let key in ball) {
          if (arr[number] === key) {
            return ball[key]
          }
        }
      },
      getPrice (number, day) {
        const that = this
        const arr = new Array()
        arr[0] = 'one'
        arr[1] = 'two'
        arr[2] = 'three'
        arr[3] = 'four'
        arr[4] = 'five'
        arr[5] = 'six'
        arr[6] = 'seven'
        arr[7] = 'eight'
        arr[8] = 'nine'
        arr[9] = 'ten'
        arr[10] = 'eleven'
        arr[11] = 'twelve'
        arr[12] = 'thirteen'
        arr[13] = 'fourteen'
        if (that.getBall(number, day)) {
          if (that.admin === true) {
            return '点击查看'
          } else {
            return '已出售'
          }
        } else {
          let list = that.priceList[day]
          for (let key in list) {
            if (arr[number] === key) {
              return '￥' + list[key]
            }
          }
        }
      },
      cpick (event) {
        const target = event.target
        const that = this
        let id = target.id
        if (this.pickList.length > 0) {
          let index = this.pickList.indexOf(id)
          let day = id.substring(0, 1)
          let time = Number(id.substring(1))
          if (index !== -1) {
            let maxAndmin2 = this.maxAndmin(day)
            if (maxAndmin2[0] === time || maxAndmin2[1] === time) {
              this.pickList.splice(index, 1)
              target.classList.remove('btn-primary')
            } else {
              this.$refs.warn.showWarn = true
              this.$refs.warn.warnText = '只能连续订场'
              setTimeout(function () {
                that.$refs.warn.showWarn = false
              }, 1000)
            }
          } else {
            let x = this.pickList.length
            this.pickList[x] = id
            target.classList.add('btn-primary')
            let index1 = this.dayList.indexOf(day)
            if (index1 !== -1) {
              let maxAndmin3 = this.maxAndmin(day)
              for (let y = maxAndmin3[0]; y < maxAndmin3[1]; y++) {
                let dom = document.getElementById(day + '' + y)
                if (dom.className.indexOf('btn-primary') < 0) {
                  let x1 = this.pickList.length
                  this.pickList[x1] = dom.getAttribute('id')
                  dom.classList.add('btn-primary')
                }
              }
            } else {
              let length = this.dayList.length
              this.dayList[length] = day
            }
            // console.log(this.dayList)
          }
        } else {
          let x = this.pickList.length
          this.pickList[x] = id
          let day = id.substring(0, 1)
          this.dayList[this.dayList.length] = day
          target.classList.add('btn-primary')
        }

      },
      cancel () {
        let sm = document.getElementsByClassName('btn-sm')
        for (let i = 0; i < sm.length; i++) {
          if (sm[i] != null) {
            sm[i].classList.remove('btn-primary')
          }
        }
        this.pickList.length = 0
        this.dayList.length = 0
      },
      maxAndmin (day) {
        let max = 1
        let min = 14
        for (let i in this.pickList) {
          if (this.pickList[i].substring(0, 1) === day) {
            let s = parseInt(this.pickList[i].substring(1))
            if (s > max) {
              max = s
            }
            if (s < min) {
              min = s
            }
          }
        }
        return [min, max]
      },
      findweek (year, month, day) {
        const dt = new Date(year, month, day)
        const weekDay = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
        return weekDay[dt.getDay()]
      },
      submit () {
        const that = this
        // eslint-disable-next-line eqeqeq
        if (that.rid != null) {
          if (that.pickList.length > 0) {
            if (that.pickList.length > 1) {
              if (that.flag()) {
                that.$axios.get(that.$url + 'findCacheOrder?rid=' + that.rid)
                  .then(function (res) {
                    let data = res.data
                    if (data === 'not') {
                      that.$refs.of.court = 'basketball'
                      that.$refs.tp.show = true
                    } else {
                      if (confirm('您还有一笔未支付的订单，是否支付')) {
                        sessionStorage.setItem('ooO', data)
                        that.$router.push('/pay')
                      }
                    }
                  })
              }else {
                that.$refs.warn.showWarn = true
                that.$refs.warn.warnText = '不能一天1小时'
                setTimeout(function () {
                  that.$refs.warn.showWarn = false
                }, 1000)
              }
            } else {
              that.$refs.warn.showWarn = true
              that.$refs.warn.warnText = '2小时起订'
              setTimeout(function () {
                that.$refs.warn.showWarn = false
              }, 1000)
            }
          } else {
            that.$refs.warn.showWarn = true
            that.$refs.warn.warnText = '您还未选中任何场次'
            setTimeout(function () {
              that.$refs.warn.showWarn = false
            }, 1000)
          }
        } else {
          that.$refs.warn.showWarn = true
          that.$refs.warn.warnText = '登陆信息已过期，请重新进入'
          setTimeout(function () {
            that.$refs.warn.showWarn = false
          }, 1000)
        }
      },
      timeCount () {
        const that = this
        let date = new Date()
        this.year = date.getFullYear()
        this.month = date.getMonth() + 1
        this.day = date.getDate()
        this.hour = date.getHours()
        this.min = date.getMinutes()
        this.sec = date.getSeconds() + 1
        if (this.hour < 10) {
          this.hour = '0' + this.hour
        }
        if (this.min < 10) {
          this.min = '0' + this.min
        }
        if (this.sec < 10) {
          this.sec = '0' + this.sec
        }
        if (this.hour > 8) {
          for (let i = 9; i <= this.hour; i++) {
            let id = '1' + (i - 8)
            let dom = document.getElementById(id)
            if (dom != null){
              dom.classList.remove('btn-default')
              dom.classList.add('btn-danger')
              dom.innerText = '已售完'
              dom.setAttribute('disabled', true)
            }
          }
        }
        this.timeText = '<span style=\'font-weight: bold\'>当前时间:</span>&nbsp;&nbsp;<span style=\'color: violet;font-weight: bold\'>' + this.year + '</span>年<span style=\'color: green;font-weight: bold\'>' + this.month + '</span>月<span style=\'color: orange;font-weight: bold\'>' + this.day + '</span>日 ' + this.hour + ':' + this.min + ':' + this.sec + '</span>'
        setTimeout(function () {
          that.timeCount()
        }, 1000)
      },
      timestampTotime (time) {
        const date = new Date(time + 8 * 3600 * 1000)
        return date.toJSON().substr(0, 19).replace('T', ' ').replace(/-/g, '.')
      },
      getRid () {
        return this.rid
      }
    }
  }
</script>

<style scoped>
  #ballRow {
    width: 550px;
    padding-left: 40px;
    height: 100%;
  }

  #ballChoose {
    width: 100vw;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 12px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .btn-danger {
    color: #fff;
    background-color: #666;
    border-color: #333;
  }

  .btn-danger:focus,
  .btn-danger.focus {
    color: #fff;
    background-color: #666;
    border-color: #333;
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #666;
    border-color: #333;
  }

  .btn-danger:active,
  .btn-danger.active,
  .open > .dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #666;
    border-color: #333;
  }

  .btn-danger:active:hover,
  .btn-danger.active:hover,
  .open > .dropdown-toggle.btn-danger:hover,
  .btn-danger:active:focus,
  .btn-danger.active:focus,
  .open > .dropdown-toggle.btn-danger:focus,
  .btn-danger:active.focus,
  .btn-danger.active.focus,
  .open > .dropdown-toggle.btn-danger.focus {
    color: #fff;
    background-color: #666;
    border-color: #333;
  }

  .btn-danger:active,
  .btn-danger.active,
  .open > .dropdown-toggle.btn-danger {
    background-image: none;
  }

  .btn-danger.disabled:hover,
  .btn-danger[disabled]:hover,
  fieldset[disabled] .btn-danger:hover,
  .btn-danger.disabled:focus,
  .btn-danger[disabled]:focus,
  fieldset[disabled] .btn-danger:focus,
  .btn-danger.disabled.focus,
  .btn-danger[disabled].focus,
  fieldset[disabled] .btn-danger.focus {
    background-color: #666;
    border-color: #333;
  }

  .btn-danger .badge {
    color: #666;
    background-color: #fff;
  }

  h1, h2, h3 {
    text-align: center;
  }

  .btn-sm {
    height: 25px;
    width: 70px;
    margin-top: 3.5px;
    margin-right: 2px
  }

  .time {
    position: absolute;
    left: -40px;
    top: -10px;
    color: black;
  }

  .date {
    position: absolute;
    top: -40px;
    left: 10px;
  }

  .datef {
    position: relative;
    float: left;
  }

  .weui-mask {
    z-index: 3001;
  }
</style>
