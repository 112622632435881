<template>
  <!--BEGIN dialog2-->

  <div class="js_dialog"
       v-show="show">
    <div class="weui-mask"></div>
    <div class="weui-dialog">
      <div class="weui-dialog__bd">券码有效，确定使用吗？</div>
      <div class="weui-dialog__ft">
        <a @click="close"
           class="weui-dialog__btn weui-dialog__btn_primary">取消</a>
        <a @click="valid"
           class="weui-dialog__btn weui-dialog__btn_primary">确定</a>
      </div>
    </div>
  </div>
  <!--END dialog2-->
  <!--BEGIN toast-->
  <div role="alert" id="toast" style="display: none;">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
      <i class="weui-icon-success-no-circle weui-icon_toast"></i>
      <p class="weui-toast__content">成功核销！卡券已添加到个人卡包</p>
    </div>
  </div>
  <div role="alert" id="warnToast" style="display: none;">
    <div class="weui-mask_transparent"></div>
    <div class="weui-toast">
      <i class="weui-icon-warn weui-icon_toast"></i>
      <p class="weui-toast__content">出错了！请联系工作人员！</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'dyv',
    data(){
      return {
        show: false,
        token: "",
        code: "",
        rid: sessionStorage.getItem('rid'),
      }
    },
    methods: {
      close(){
        this.show = false
      },

      valid(){
        const that = this
        this.show = false
        console.log(this.code)
        console.log(this.token)
        this.$axios.get(this.$url + '/dy/valid?code='+this.code+"&token="+this.token+"&rid="+this.rid)
          .then(function(res) {
            console.log(res)
            if (res.data) {
              $("#toast").fadeIn(100)
              setTimeout(function () {
                $("#toast").fadeOut(100);
              }, 2000);
            } else {
              $("#warnToast").fadeIn(100);
              setTimeout(function () {
                $("#warnToast").fadeOut(100);
              }, 1000);
            }
          });
      },


    }
  };
</script>

<style scoped>

</style>
