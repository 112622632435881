<template>
  <!--BEGIN dialog2-->

  <div class="js_dialog"
       v-show="show">
    <div class="weui-mask"></div>
    <div class="weui-dialog">
      <div class="weui-dialog__bd">注意！培训班和比赛相关目的不允许订场，订场请联系球场官方。若有以上目的仍预订球场，官方有权取消该次预订</div>
      <div class="weui-dialog__ft">
        <a @click="close"
           class="weui-dialog__btn weui-dialog__btn_primary">知道了</a>
      </div>
    </div>
  </div>
  <!--END dialog2-->
</template>

<script>
  export default {
    name: 'tips',
    data(){
      return {
        show: false,
      }
    },
    methods: {
      close(){
        this.show = false
        this.$parent.$refs.of.showOrderForm = true
      }
    }
  };
</script>

<style scoped>

</style>
