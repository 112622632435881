<template>
  <div class="js_dialog"
       v-show="showDialog">
    <div class="weui-mask"></div>
    <div class="weui-dialog">
      <div class="weui-dialog__bd">{{dialogText}}</div>
      <div class="weui-dialog__ft">
        <a @click="close" class="weui-dialog__btn weui-dialog__btn_default">取消</a>
        <a @click="toPay" class="weui-dialog__btn weui-dialog__btn_primary">去支付</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'dialog',
    data() {
      return {
        showDialog: false,
        dialogText: '',
        ooO: '',
      }
    },
    methods: {
      close(){
        this.showDialog = false
        // this.$parent.$refs.of.showOrderForm = true
      },
      toPay(){
        sessionStorage.setItem('ooO', this.ooO)
        this.$router.push('/pay')
      }
    }
  }
</script>

<style scoped>

</style>
