<template>
  <div class="container-fluid">
    <div class="row">
      <ul id="menu"
          class="nav nav-tabs">
        <li id="basketballPrice"
            class="active"><a data-toggle="tab"
                                    href="#home">篮球场</a></li>
        <li id="tennisPrice"><a data-toggle="tab"
                                href="#menu1">网球场</a></li>
      </ul>

      <div class="tab-content">
        <div class="col-xs-12"
             style="margin-top: 15px;margin-bottom: 15px;padding-right: 15px;padding-left: 15px">
          <button id="showDatePicker"
                  class="btn btn-info"
                  style="margin-right: 3px" type="button">选择日期
          </button>
          <button class="btn btn-warning"
                  type="button"
                  @click="this.$router.push('/user')">返回
          </button>
        </div>
        <div id="home"
             class="tab-pane fade in active">
          <input id="year"
                 type="hidden">
          <input id="month"
                 type="hidden">
          <input id="day"
                 type="hidden">
          <div v-show="basketballS" style="padding-left: 5px;padding-right: 5px;margin-top: 15px">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>场次</th>
                <th>价格</th>
                <th>操作</th>
              </tr>
              </thead>
              <tbody id="tbody">
              <tr v-for="(val, key, index) in basketballList">
                <td>{{ index }}：00~{{ index + 1 }}：00</td>
                <td :id='key'>{{ val }}</td>
                <td>
                  <button class="btn btn-default" type="button" @click="writemsg(key,'basketball')">修改</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <h3 id="h3"
              style="display:none;text-align: center">暂无内容</h3>
        </div>
        <div id="menu1"
             class="tab-pane fade">
          <div v-show="tennisS" style="padding-left: 5px;padding-right: 5px;margin-top: 15px">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>场次</th>
                <th>价格</th>
                <th>操作</th>
              </tr>
              </thead>
              <tbody id="tb">
              </tbody>
            </table>
          </div>
          <h3 id="h32"
              style="display:none;text-align: center">暂无内容</h3>
        </div>
      </div>
      <!--BEGIN dialog1-->
      <div v-show="basketballDialog"
           class="js_dialog"
           style="display: none;">
        <div class="weui-mask"></div>
        <div class="weui-dialog">
          <div class="weui-dialog__hd"><strong class="weui-dialog__title">输入价格</strong></div>
          <div class="weui-dialog__bd">
            <input v-model="basketballPrice"
                   placeholder="请输入价格"
                   type="number">
          </div>
          <div class="weui-dialog__ft">
            <a class="weui-dialog__btn weui-dialog__btn_default"
               @click="cancel('basketball')">取消</a>
            <a id="y"
               class="weui-dialog__btn weui-dialog__btn_primary"
               @click="update('basketball')">确定</a>
          </div>
        </div>
      </div>
      <!--END dialog1-->
      <!--BEGIN dialog1-->
      <div v-show="tennisDialog" class="js_dialog">
        <div class="weui-mask"></div>
        <div class="weui-dialog">
          <div class="weui-dialog__hd"><strong class="weui-dialog__title">输入价格</strong></div>
          <div class="weui-dialog__bd">
            <input v-model="tennisPrice" placeholder="请输入价格"
                   type="number">
          </div>
          <div class="weui-dialog__ft">
            <a class="weui-dialog__btn weui-dialog__btn_default"
               @click="cancel('tennis')">取消</a>
            <a id="y1"
               class="weui-dialog__btn weui-dialog__btn_primary"
               @click="update('tennis')">确定</a>
          </div>
        </div>
      </div>
      <warn ref="wn"></warn>
      <toast ref="tt"></toast>
    </div>
  </div>
</template>

<script>
import Warn from '@/components/modular/warn'
import Toast from '@/components/modular/toast'
import $ from 'jquery'
import weui from 'weui.js'
import { list } from 'bootstrap/grunt/browsers'

export default {
  name: 'updatePrice',
  components: {
    Toast,
    Warn
  },
  data () {
    return {
      basketballDialog: false,
      basketballPrice: '',
      basketballS: false,
      basketballList: {},
      tennisPrice: '',
      tennisS: false,
      tennisList: [],
      tennisDialog: false,
      year: 0,
      month: 0,
      day: 0,
      rid: 0,
      sessions: '',
      list: []
    }
  },
  created () {
    const that = this
    this.rid = sessionStorage.getItem('rid')
    if (this.rid == null) {
      this.$router.push('/wxWarn')
    } else {
      this.$axios.post(that.$url + 'findAdministratorsOfRole', 'rid=' + this.rid)
        .then(function (res) {
          let data = res.data
          if (data === false) {
            that.$router.push('/wxWarn')
          }
        })
    }
  },
  mounted () {
    const that = this
    const arr = new Array()
    arr[0] = 'one'
    arr[1] = 'one1'
    arr[2] = 'two'
    arr[3] = 'two1'
    arr[4] = 'three'
    arr[5] = 'three1'
    arr[6] = 'four'
    arr[7] = 'four1'
    arr[8] = 'five'
    arr[9] = 'five1'
    arr[10] = 'six'
    arr[11] = 'six1'
    arr[12] = 'seven'
    arr[13] = 'seven1'
    arr[14] = 'eight'
    arr[15] = 'eight1'
    arr[16] = 'nine'
    arr[17] = 'nine1'
    arr[18] = 'ten'
    arr[19] = 'ten1'
    arr[20] = 'eleven'
    arr[21] = 'eleven1'
    arr[22] = 'twelve'
    arr[23] = 'twelve1'
    arr[24] = 'thirteen'
    arr[25] = 'thirteen1'
    arr[26] = 'fourteen'
    arr[27] = 'fourteen1'
    arr[28] = 'fifteen'
    arr[29] = 'fifteen1'
    arr[30] = 'sixteen'
    arr[31] = 'sixteen1'
    arr[32] = 'seventeen'
    arr[33] = 'seventeen1'
    arr[34] = 'eighteen'
    arr[35] = 'eighteen1'
    arr[36] = 'nineteen'
    arr[37] = 'nineteen1'
    arr[38] = 'twenty'
    arr[39] = 'twenty1'
    arr[40] = 'twentyOne'
    arr[41] = 'twentyOne1'
    arr[42] = 'twentyTwo'
    arr[43] = 'twentyTwo1'
    arr[44] = 'twentyThree'
    arr[45] = 'twentyThree1'
    arr[46] = 'twentyFour'
    arr[47] = 'twentyFour1'
    that.list = arr
    this.$axios.post(that.$url + 'findPriceAllYear')
      .then(function (res) {
        let data = res.data
        $('#showDatePicker').on('click', function () {
          weui.datePicker({
            start: data[0],
            end: data[data.length - 1],
            onConfirm: function (result) {
              $('#year').val(result[0].value)
              $('#month').val(result[1].value)
              $('#day').val(result[2].value)
              let id = $('#menu').children('li.active').attr('id')
              if (id.indexOf('basketball') !== -1) {
                that.findPriceOfDate(result[0].value, result[1].value, result[2].value, 'basketball')
              }
              if (id.indexOf('tennis') !== -1) {
                that.findPriceOfDate(result[0].value, result[1].value, result[2].value, 'tennis')
              }
            },
            title: '选择日期'
          })
        })
      })
    window.writemsg=(id, name)=>{
      this.writemsg(id, name)
    }
  },
  methods: {
    show (name) {
      if (name === 'basketball') {
        this.basketballDialog = true
      }
      if (name === 'tennis') {
        this.tennisDialog = true
      }
    },
    cancel (name) {
      if (name === 'basketball') {
        this.basketballDialog = false
      }
      if (name === 'tennis') {
        this.tennisDialog = false
      }
    },
    update (name) {
      const that = this
      let url, price
      if (name === 'basketball') {
        url = 'updateBasketballPrice'
        price = that.basketballPrice
        that.sessions = document.getElementById('y').getAttribute('name')
      }
      if (name === 'tennis') {
        url = 'updateTennisPrice'
        price = that.tennisPrice
        that.sessions = document.getElementById('y1').getAttribute('name')
      }
      if (price > 0) {
        that.year = $('#year').val()
        that.month = $('#month').val()
        that.day = $('#day').val()
        let str = 'sessions=' + that.sessions + '&year=' + that.year + '&month=' + that.month + '&day=' + that.day + '&price=' + price + '&rid=' + that.rid
        that.$axios.post(that.$url + url, str)
          .then(function (res) {
            let data = res.data
            if (data.Bflag) {
              that.cancel(name)
              that.$refs.tt.showToast = true
              setTimeout(function () {
                that.$refs.tt.showToast = false
                that.findPriceOfDate(that.year, that.month, that.day, name)
              }, 1400)
            } else {
              that.cancel(name)
              that.$refs.wn.warnText = data.Msg
              that.$refs.wn.showWarn = true
              setTimeout(function () {
                that.$refs.wn.showWarn = false
              }, 1400)
            }
          })
      } else {
        that.$refs.wn.warnText = '输入价格为空'
        that.$refs.wn.showWarn = true
        setTimeout(function () {
          that.$refs.wn.showWarn = false
          that.show(name)
        }, 1400)
      }
    },
    writemsg (id, name) {
      if (name === 'basketball') {
        document.getElementById('y').setAttribute('name', id)
      }
      if (name === 'tennis') {
        document.getElementById('y1').setAttribute('name', id)
      }
      this.show(name)
    },
    findPriceOfDate (year, month, day, name) {
      const that = this
      $("#tb").html("")
      let url
      if (name === 'basketball') {
        url = 'findBasketballPriceOfDate'
      }
      if (name === 'tennis') {
        url = 'findTennisPriceOfDate'
      }
      that.$axios.post(that.$url + url, 'year=' + year + '&month=' + month + '&day=' + day)
        .then(function (res) {
          let data = res.data
          if (data != null) {
            delete data.year
            delete data.month
            delete data.day
            if (name === 'basketball') {
              $('#h3').fadeOut(200)
              that.basketballList = data
              that.basketballS = true
            }
            if (name === 'tennis') {
              $('#h32').fadeOut(200)
              that.tennisList = data
              that.tennisS = true
              for (let i = 0; i < that.list.length; i++) {
                for (const key in data) {
                  if (that.list[i] === key) {
                    let src =  ""
                    if (i < 2 ){
                      if (i == 0) {
                        src = "<tr>"+
                          "<td>0:00~0:30</td>" +
                          "<td>"+data[key]+"</td>" +
                          "<td><button type=\"button\"  class=\"btn btn-default\" onclick=\"writemsg('"+key+"','tennis')\">修改</button></td>" +
                          "</tr>"
                      } else {
                        src = "<tr>"+
                        "<td>0:30~1:00</td>" +
                        "<td>"+data[key]+"</td>" +
                        "<td><button type=\"button\"  class=\"btn btn-default\" onclick=\"writemsg('"+key+"','tennis')\">修改</button></td>"+
                          "</tr>"
                      }
                    } else {
                      if (i % 2 === 1) {
                        src = "<tr>"+
                          "<td>"+ (i-1)/2 +":30~"+ Number((i-1)/2 +1) +":00</td>" +
                          "<td>"+data[key]+"</td>" +
                          "<td><button type=\"button\"  class=\"btn btn-default\" onclick=\"writemsg('"+key+"','tennis')\">修改</button></td>"+
                          "</tr>"
                      } else {
                        src = "<tr>"+
                          "<td>"+(i/2)+":00~"+(i/2)+":30</td>" +
                          "<td>"+data[key]+"</td>" +
                          "<td><button type=\"button\"  class=\"btn btn-default\" onclick=\"writemsg('"+key+"','tennis')\">修改</button></td>"+
                          "</tr>"
                      }
                    }
                    $("#tb").append(src)
                  }
                }
              }

            }
          } else {
            if (name === 'basketball') {
              $('#h3').fadeIn(200)
            }
            if (name === 'tennis') {
              $('#h32').fadeIn(200)
            }
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
